import { Component, OnDestroy } from '@angular/core';
import { ModalService, ToasterService } from '@purespectrum1/ui';
import { Subscription } from 'rxjs';
import * as fileSaver from 'file-saver';
import { notifyMessage } from './../../constants/notify-message';
import { UnicornFileService } from './unicorn-file.service';
import { UploadFileResponse } from './unicorn-file.interface';
import { Constants } from '../operator-settings-constants';
import { ConfirmationModalComponent } from '../../shared/ui/confirmation-modal/confirmation-modal.component';
import { filter, switchMap } from 'rxjs/operators';
import { HttpHeadersExtractor } from '../../utils/http-headers-extractor';

@Component({
  selector: 'ps-unicorn-quotas-settings',
  templateUrl: './unicorn-quotas-settings.component.html',
  styleUrls: ['./unicorn-quotas-settings.component.css'],
})
export class UnicornQuotasSettingsComponent implements OnDestroy {
  private _subscriptions: Subscription = new Subscription();
  public unicornSettings = Constants.UNICORN_QUOTAS_SETTINGS;

  constructor(
    private _unicornFileService: UnicornFileService,
    private _modal: ModalService,
    private _toastr: ToasterService
  ) {}

  public downloadFile() {
    this._subscriptions.add(
      this._unicornFileService.downloadFile().subscribe(
        (response) => {
          const fileName =
            new HttpHeadersExtractor(response.headers).filename() ||
            'unicorn-quotas.xlsx';
          fileSaver.saveAs(response.body as Blob, fileName);
        },
        ({
          data: {
            message = notifyMessage.errorMessage.FILE_DOWNLOAD_FAILED,
          } = {},
        } = {}) => {
          this._toastr.error(message);
        }
      )
    );
  }

  public uploadFile(event: Event) {
    const target = event.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];
    const isValidFile = this._validateFile(file);
    if (isValidFile) {
      this._modal
        .open(ConfirmationModalComponent, {
          data: {
            text: Constants.CONFIRM_MODAL_TEXT.UPLOAD_UNICORN,
            confirmButtonLabel: 'Confirm',
          },
          maxWidth: '40%',
        })
        .onClose$.pipe(
          filter((msg) => msg === 'ok'),
          switchMap(() => {
            const formData = new FormData();
            formData.append('file', file);
            return this._unicornFileService.uploadFile(formData);
          })
        )
        .subscribe(
          (response: UploadFileResponse) => {
            const { msg = '' } = response;
            this._toastr.success(msg);
          },
          ({ error }) => {
            const message =
              error.ps_api_response_message ??
              notifyMessage.errorMessage.SERVER_ERROR;
            this._toastr.error(message);
          }
        );
    }
  }

  private _validateFile(file: File) {
    const validExt = ['.csv', '.xls', '.xlsx'];
    const fileExt = file?.name?.substring(file?.name?.lastIndexOf('.'));
    if (!file || validExt.indexOf(fileExt) < 0) {
      this._toastr.error(notifyMessage.errorMessage.FILE_UPLOAD_MISSING);
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
