import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { UploadFileResponse } from './unicorn-file.interface';

@Injectable({
  providedIn: 'root',
})
export class UnicornFileService {
  private readonly _baseUrl = environment.pureSpectrum.url;

  constructor(private _http: HttpClient) {}

  uploadFile(formData: FormData): Observable<UploadFileResponse> {
    return this._http.post<UploadFileResponse>(
      `${this._baseUrl}/buyers/v2/unicorn/upload`,
      formData
    );
  }

  downloadFile(): Observable<HttpResponse<Blob>> {
    return this._http.get<Blob>(`${this._baseUrl}/buyers/v2/unicorn/download`, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }
}
