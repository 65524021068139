<div class="container">
  <div class="row d-flex justify-content-center">
    <h6 class="text-uppercase text-center fw-bold mb-5">
      Unicorn Quotas Settings
    </h6>
    <div class="d-flex justify-content-center">
      <input
        #unicornQuotasFileUpload
        hidden
        type="file"
        class="file-upload"
        accept=".csv, .xlsx, .xls"
        (change)="uploadFile($event)"
      />
      <button
        type="button"
        class="btn btn-link upload-button fw-bold"
        (click)="
          unicornQuotasFileUpload.value = ''; unicornQuotasFileUpload.click()
        "
      >
        Upload a file for All
      </button>
    </div>
    <div class="d-flex justify-content-center">.csv, .xlsx, .xls</div>
    <div class="d-flex justify-content-center p-3">
      <a
        type="button"
        class="btn btn-link download-button"
        [href]="unicornSettings.templatePath"
      >
        Download Template
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <button
        type="button"
        class="btn btn-link download-button"
        (click)="downloadFile()"
      >
        Download Mappings XLSX
      </button>
    </div>
  </div>
</div>
