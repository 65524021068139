<main class="container">
  <p class="operator-settings-text">Operator Settings</p>

  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <ps-ui-tab-group
        [activeIndex]="activeTabIndex"
        (activeIndexChange)="setActiveTab($event)"
      >
        <ps-ui-tab *ngFor="let tab of operatorSettingsTabs" [label]="tab.title">
          <ng-template psUiTabContent>
            <div *ngIf="tab.key === 'MANAGE_FEASIBILITY'">
              <ps-manage-feasibility></ps-manage-feasibility>
            </div>

            <div *ngIf="tab.key === 'MARGIN_SETTINGS'">
              <ps-margin-settings></ps-margin-settings>
            </div>

            <div *ngIf="tab.key === 'SUPPLIERS_SETTINGS'">
              <ps-supplier-settings></ps-supplier-settings>
            </div>

            <div *ngIf="tab.key === 'CURRENCY_SETTINGS'">
              <ps-currency-settings></ps-currency-settings>
            </div>

            <div *ngIf="tab.key === 'GLOBAL_SETTINGS'">
              <ps-global-settings></ps-global-settings>
            </div>

            <div *ngIf="tab.key === 'CONFIG_SETTINGS'">
              <ps-config-settings></ps-config-settings>
            </div>

            <div *ngIf="tab.key === 'MANAGE_CENSUS'">
              <ps-manage-census></ps-manage-census>
            </div>

            <div *ngIf="tab.key === 'IPSOS_MAPPINGS'">
              <ps-ipsos-mappings></ps-ipsos-mappings>
            </div>

            <div *ngIf="tab.key === 'EXTERNAL_BUYER_MAPPINGS'">
              <ps-external-buyer-mappings></ps-external-buyer-mappings>
            </div>

            <div *ngIf="tab.key === 'GENPOP_SETTINGS'">
              <ps-genpop-settings></ps-genpop-settings>
            </div>

            <div *ngIf="tab.key === 'SAMPLIFY_MAPPINGS'">
              <ps-samplify-mappings></ps-samplify-mappings>
            </div>

            <div *ngIf="tab.key === 'CRM_ID'">
              <ps-crm-id></ps-crm-id>
            </div>
            <div *ngIf="tab.key === 'PROGRAMMATIC DIRECT SETTINGS'">
              <ps-programmatic-direct-settings></ps-programmatic-direct-settings>
            </div>
            <div *ngIf="tab.key === 'CORE_MAPPINGS'">
              <ps-core-mappings></ps-core-mappings>
            </div>
            <div *ngIf="tab.key === 'TRAFFIC_DENSITY_FILE'">
              <ps-traffic-density-file></ps-traffic-density-file>
            </div>
            <div *ngIf="tab.key === 'DATA_QUALITY_SETTINGS'">
              <ps-data-quality-settings></ps-data-quality-settings>
            </div>
            <div *ngIf="tab.key === 'PUREPRICE_SETTINGS'">
              <ps-pureprice-settings></ps-pureprice-settings>
            </div>
            <div *ngIf="tab.key === 'BUYER_RATE_CARD'">
              <ps-buyer-rate-card></ps-buyer-rate-card>
            </div>
            <div *ngIf="tab.key === 'UNICORN_QUOTAS_SETTINGS'">
              <ps-unicorn-quotas-settings></ps-unicorn-quotas-settings>
            </div>
          </ng-template>
        </ps-ui-tab>
      </ps-ui-tab-group>
    </div>
  </div>
</main>
