import { HttpHeaders } from '@angular/common/http';

export class HttpHeadersExtractor {
  constructor(private readonly _headers: HttpHeaders) {}

  filename(): string {
    const contentDisposition = this._headers.get('content-disposition');
    if (!contentDisposition) {
      return '';
    }
    const dispositions = contentDisposition
      .split(';')
      .map((disposition) => disposition.trim());
    const filenameDisposition = dispositions.find((disposition) =>
      disposition.includes('filename')
    );
    if (!filenameDisposition) {
      return '';
    }
    return filenameDisposition.split('=')[1].replace(/"/g, '');
  }
}
